import * as React from 'react';
import { useEffect,useState } from 'react';
import FaceService from '../services/face.service';
import TagSearchService from '../services/tagSearch.service';
import FaceComponent from '../components/Face.Component';
import {IFace } from '../@types/Face';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';
import { useDetectAdBlock } from "adblock-detect-react";

const TagSearchComponent:React.FC = () => {
    const adBlockDetected = useDetectAdBlock();
  
    const firstLoadNum = 50;
    const loadMoreSteps = 25;
    const loadMoreMax = firstLoadNum + (loadMoreSteps * 2);

    const [isMore, setIsMore] = useState<boolean>(false);
    const [loadMoreNum, setloadMoreNum] = useState<number>(firstLoadNum);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [faces,popFaceData] = React.useState<IFace[]>([]);
    const [searchString, setSearchString] = useState("");//latina big tits blue eyes

    const faceService = FaceService.getInstance();
    const tagSearchService = TagSearchService.getInstance();

    const handleAdBlock= () => {
      var ads = document.getElementById('ads');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_m');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_sm');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
    }

    useEffect(()=>{ 
      document.title = 'Camgirl model keyword and tag search engine';
      let descMeta=document.querySelector("meta[name='description']");
      if(descMeta!==null)descMeta.setAttribute("content", 'Camgirl model finder with our tag and keyword search engine. Search and find camgirls using keywords and tags with our engine search.');
      let canNAme=document.querySelector("link[rel='canonical']");
      if(canNAme!==null)canNAme.setAttribute("href", 'https://camgirlcrawler.com/camgirl-tag-search');

        getPopularFaces();
       }, []); // empty array means only once

      const handleSubmit = (event:any) => {
        event.preventDefault();
        popFaceData([]);
        tagSearch(0,firstLoadNum);
      }

      const loadMore = (event:any) => {
        event.preventDefault();
        tagSearchLoadMore(loadMoreNum , loadMoreSteps);
      }

      const getPopularFaces = () =>
      {
        faceService.getPopularSearches().then(response => { 
          if(response.length >0 && IsFace(response[0])){
            popFaceData(response);
            }else{
              popFaceData([]);
          } 
          }).catch(error => {
              console.log(error)
          });
      }
      const tagSearch = (skip: number, take: number) => {

        setErrorMsg("");
        setloadMoreNum(firstLoadNum);
        setIsMore(false);
        setLoading(true);

        if(searchString == '')
        {
            popFaceData([]);
            getPopularFaces();
            return;
        }

        tagSearchService.getTagSearchResults(searchString,skip,take).then(response => { 
          if(response.length >0 && IsFace(response[0])){
              if(response[response.length-1].name === "==")
              {
                setIsMore(true);
                response.pop();
              }else
                setIsMore(false);
              popFaceData(response);
           }else{
              setErrorMsg(handleHttpError(response));
           } 
        }).catch(error => {
          setErrorMsg(handleHttpError(500));
          console.log(error)
        }).then(() =>{
              setLoading(false);
        });
      }

      const tagSearchLoadMore = (skip: number, take: number) => {

        if(loadMoreNum >= loadMoreMax) return;

        setLoading(true);

        tagSearchService.getTagSearchResults(searchString,skip,take).then(response => {         
          if(response.length>0 && IsFace(response[0])){
            if(response[response.length-1].name === "==")
              {
                setIsMore(true);
                response.pop();
              }else
                setIsMore(false);

            var existingFaceData = faces;
            var newArray = existingFaceData.concat(response);
            popFaceData(newArray);
            if(response.length >= loadMoreSteps)
                  setloadMoreNum(loadMoreNum + loadMoreSteps);
            else
                setloadMoreNum(loadMoreMax);
          }else{
            setIsMore(false);
          } 
        }).catch(error => {
          setErrorMsg(handleHttpError(500));
          console.log(error)
        }).then(() =>{
              setLoading(false);
        });
      }

        // this is manly to stop typescript from complaining 
     const AdBlockFn = handleAdBlock as any;

    return (
        <div>
            <AdBlockFn/>
            {adBlockDetected && (
              <div className="alert alert-danger errorMessage" role="alert" style={{marginTop:'30px'}}>
                If you are using an Ad Blocker the link to model may not work!<br/>Please turn off your Ad Blocker!
              </div>
            )}
            <h1 style={{marginTop:'50px'}}>Camgirl Tag Search</h1>
            <form onSubmit={handleSubmit}>
            <div className="input-group" style={{width:'30%',margin:'auto',paddingLeft:'30px',marginTop:'30px',marginBottom:'30px'}}>
              <input className="form-control" value={searchString} placeholder='e.g. latina big tits' onChange={(e) => setSearchString(e.target.value)} style={{fontSize:'1.25rem',color:'#626163'}}/>
              <span className="input-group-addon">
                  <button className="btn" style={{background:'#E0E0E0'}} onClick={handleSubmit}>
                  <img src={'images/search.png'} alt="Copy share link to clipboard" title="Copy share link to clipboard" width={20} height={20} style={{cursor:'pointer'}}/>
                  </button>
              </span>
            </div> 
            </form>         
            <div>{loading && faces.length ==0 &&(                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>  
            {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter'>
              {!errorMsg && faces.length>0 &&  faces.map((face: IFace) => (
                <FaceComponent key={face.name}  face={face} imgWidth='150' imgHeight='150'/>
                    ))    
                } 
            </div>
            {!errorMsg && isMore && loadMoreNum < loadMoreMax && (
            <div style={{margin:'30px'}}>
            <button
                  className="btn btn-success"
                  onClick={loadMore}>
                   Show More
                </button>
            </div> 
            )} 
            <div>{loading && faces.length > 0 && loadMoreNum < loadMoreMax && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>  
            <div className='info' style={{marginTop:'75px'}}>
            <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h3>What we do:</h3>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li >Crawl popular camgirl model plaforms for face images</li>
                        <li>Retain a camgirl model's face images for up to six months for searches</li>             
                        <li>Perform face image search engine matching for camgirl model's</li>
                        <li>Provide a tag and keyword search engine for finding camgirl model's</li>
                        <li>Link to a camgirl model found in a face image or tag search</li>
                        <li>Work with camgirl model plaforms as an affiliate</li>
                        <li>Respect camgirl model's privacy</li> 
                        <li>Respect your (the user's) privacy</li>
                    </div>    
            </section>
          </div>
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h4>What we don't do:</h4>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li>Post the real name of camgirl models</li> 
                        <li>Retain camgirl model's face images beyond six months if not seen in searches</li>
                        <li>Show recorded videos of a camgirl model performing</li>
                        <li>Find a camgirl model's real social media accounts</li>
                        <li>Charge for our camgirl model face image search service</li> 
                        <li>Retain any of your (the user's) usage details</li>              
                    </div>    
            </section>
          </div>
          </div>
        </div>
  );
}
export default TagSearchComponent;