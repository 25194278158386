import axios from "axios";
const https = require('https');

const selfCerthttpsAgent = new https.Agent({
  rejectUnauthorized: false
});

export default class TagSearchService {
 
    private static instance: TagSearchService;
  
    baseUrl: string;
    private constructor() { 
  
      const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
      this.baseUrl = baseUrl! + '/search';
    }
  
    public static getInstance(): TagSearchService {
      if (!TagSearchService.instance) {
        TagSearchService.instance = new TagSearchService();
      }
      return TagSearchService.instance;
    }

 getTagSearchResults = (searchString: string, skip: number, take: number): Promise<any> => {
  return axios.get(this.baseUrl + '/default', {
    params: {
        searchString: searchString,
        skip: skip,
        take: take
    },
    httpsAgent: selfCerthttpsAgent,
    timeout: 60000,
    headers: {
      "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
    }
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  });
 };
}