import 'bootstrap/dist/css/bootstrap.min.css';
import { useState,useEffect } from 'react';
import Main from './components/Main.component';
import { Link } from "react-router-dom";
import {CookieConsent,resetCookieConsentValue,getCookieConsentValue}  from "react-cookie-consent";
import {Modal, Button} from 'react-bootstrap';
import './App.css';

function App() {
      //modal
      const [show, setShow] = useState(false);
      const handleClose = () => {
        window.sessionStorage.setItem('agreedToSexualContentPopup','true')
        setShow(false);
      }
      useEffect(()=>{ 
        var cookieVal = getCookieConsentValue("camgirlcrawler");

        var val = window.sessionStorage.getItem('agreedToSexualContentPopup');
        if((val === undefined || val == null) || val!='true')
          setShow(true);

      }, []); // empty array means only once

      function redirectAway(){
        window.location.replace('https://www.google.com')
      };

  return (
      <div className={"App " + (show ? 'blur' : '')}>
        <div className="topMenu">
          <div style={{display: 'inline-block',marginTop:0}}> <Link to={`/`}><p>CamgirlCrawler</p></Link></div>
          <div id="logo">
          <picture>
            <source media="(max-width: 600px)" srcSet="images/logo_sm.gif" width={'61px'} height={'63px'}  />
            <source media="(min-width: 600px)" srcSet="images/logo.gif" width={'81px'} height={'84px'} />
            <img src="/images/logo.gif" width={'81px'} height={'84px'}  alt="CamgirlCrawler is a face recognition based cam girl model search engine"/>
          </picture>
          </div>       
        </div>
        <div className="topMenuNav">
            <Link  to={`/`}>FACE SEARCH</Link>
            <Link  to={`/camgirl-tag-search`}>CAMGIRL SEARCH</Link>
            <a href='camgirl_facial_recognition_search_guide.html'>GUIDE</a>
            <a href='new-hot-camgirl-blog.html'>BLOG</a>
        </div>  
        <Main /> 
        <Modal show={show} className='modal-lg' aria-label="Sexual Content Warning!" aria-labelledby='scw'>
        <Modal.Header>
            <Modal.Title>Sexual Content Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{textAlign:'center'}}>
          <p  id='scw'>This web page may include images that may contain sexually explicit material<br/>that might not be suitable or offensive for some viewers<br/>
          <br/>
          <b>Enter ONLY if you are at least 18 years old</b>
          <p  id='scw'>and agree to our <a href='terms_and_conditions_of_use.html' target='_blank'>Terms and Conditions of Use</a></p>
          </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-danger' onClick={handleClose}>I accept and I am at least 18 years old</Button>
          <Button className='"btn btn-warning' onClick={redirectAway}>Nope, get me out of here!</Button>
        </Modal.Footer>
        </Modal>
        <CookieConsent
                location="bottom"              
                buttonText="&nbsp;&nbsp;I Accept&nbsp;&nbsp;"
                enableDeclineButton
                onDecline={resetCookieConsentValue}
                cookieName="camgirlcrawler"
                style={{ background: "#2B373B"}}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
              >
                We use essential cookies to make our site work. With your consent we may also use non-essential cookies to improve user
                experience and analyze website traffic.<br/>By clicking "Accept" you agree to our website's cookie use as described in our
                &nbsp;<a href='cookie_policy.html' target='_blank' style={{color:'white',textDecoration:'underline'}}>Cookie Policy </a>{" "}
        </CookieConsent>
      </div>
  );
}
export default App;