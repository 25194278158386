import axios from "axios";
const https = require('https');

const selfCerthttpsAgent = new https.Agent({
  rejectUnauthorized: false
});

export default class FaceService {
 
    private static instance: FaceService;
  
    baseUrl: string;
    private constructor() { 
  
      const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
      this.baseUrl = baseUrl! + '/face';
    }
  
    public static getInstance(): FaceService {
      if (!FaceService.instance) {
        FaceService.instance = new FaceService();
      }
      return FaceService.instance;
    }
    getPopularSearches = (): Promise<any> => {
      return axios.get(this.baseUrl + '/popular_search', {
        httpsAgent: selfCerthttpsAgent,
        timeout: 60000,
        headers: {
          "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
        }
      }).then((response) => {
            return response.data;
      })
      .catch((error) => {
        return error.response;
      });
     };

 getDefaultThumbnail = (site: string, name: string): Promise<any> => {
  return axios.get(this.baseUrl + '/default', {
    params: {
      site: site,
      name: name
    },
    httpsAgent: selfCerthttpsAgent,
    timeout: 60000,
    headers: {
      "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
    }
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  });
 };

 imageSearch = (file: any, onUploadProgress: any): Promise<any> => {
  let formData = new FormData();

  formData.append("image", file);

  return axios.post(this.baseUrl + '/image_search', formData, {
    httpsAgent: selfCerthttpsAgent,
    timeout: 60000,
    headers: {
      "Content-Type": "multipart/form-data",
      "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
    },
    onUploadProgress,
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  });
 };
}