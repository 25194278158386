import {IFace } from '../@types/Face';
import { useEffect,useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts'

type IProps = {
  face: IFace;
  imgWidth: string,
  imgHeight: string
};

const FaceComponent: React.FC<IProps> = ( props:IProps) => {

  const [copiedText, copy] = useCopyToClipboard();
  const [currentPersonUrl, setPersonUrl] = useState('');
  const [currentShareUrl, setShareUrl] = useState('');
  const [currentThumbnailUrl, setThumbnailUrl] = useState('');
  const [lastSeenstr, seLastSeenstr] = useState('');
  const [siteICO, setSiteICO] = useState('');
  const [genderICO, setGenderICO] = useState('');

  useEffect(()=>{
    seLastSeenstr(date_TO_String(new Date(props.face.lastSeen)));
    seLastSeenstr(props.face.lastSeenMsg);
    setThumbnailUrl(props.face.thumbnailUrl);
    setPersonUrl(props.face.personUrl);
    setShareUrl('https://camgirlcrawler.com/model/' + props.face.siteCode + '/' + props.face.name);
    setSiteICO('images/' + props.face.siteCode + '.ico');
    setGenderICO('images/' + props.face.gender + '.png');
}, []); // empty array means only once

  const handleImageError = () => {
    setThumbnailUrl('images/unknown.gif');
   }  

   function date_TO_String(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      date_Object.getDate() +
      "/" +
      (date_Object.getMonth() + 1) +
      "/" +
      +date_Object.getFullYear();

    return date_String;
  }

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        console.log('Copied!', { text })
      })
      .catch(error => {
        console.error('Failed to copy!', error)
      })
  }

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };

return (
    <div className="results">
      <div>
            <div className='thumb' role="link" onClick={() => openInNewTab(currentPersonUrl)} style={{cursor:'pointer'}}>
              <div className='thumbInner'>
              <div className={(props.face.isOnline ? 'onlineShow' : 'onlineHide')}>ONLINE</div>
                <img  onError={handleImageError} src={`${currentThumbnailUrl}`}  width={props.imgWidth} height={props.imgHeight} alt={`Click to visit ${props.face.name}`} title={`Click to visit ${props.face.name}`} />
              </div>     
            </div>
            <div>              
              <span>
                <img src={`${siteICO}`} alt={`${props.face.site}`} title={`${props.face.site}`}  width={16} height={16}/>
                &nbsp;&nbsp;<span onClick={() => openInNewTab(currentPersonUrl)} style={{cursor:'pointer'}}>{props.face.name}</span>&nbsp;
                {Number(props.face.certainty) > 0 && (   
                props.face.certainty + '%'
                )}
                &nbsp;<img onClick={handleCopy(currentShareUrl)} src={'images/share.png'} alt="Copy share link to clipboard" title="Copy share link to clipboard" width={16} height={16} style={{cursor:'pointer'}}/>
              </span><br/>
              <span>Seen: {lastSeenstr}</span>
            </div>  
    </div>
  </div>
  );
}
export default FaceComponent;