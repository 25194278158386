import * as React from 'react';
import { useEffect,useState,useRef } from 'react';
import Dropzone from "react-dropzone";
import {IFace } from '../@types/Face';
import FaceService from '../services/face.service';
import FaceComponent from '../components/Face.Component';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';
import { Helmet } from 'react-helmet';
import { useDetectAdBlock } from "adblock-detect-react";

const Faces = () => {
    const adBlockDetected = useDetectAdBlock();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [faces,popFaceData] = React.useState<IFace[]>([]);
    const [popFaceSearches,popPopFaceSearches] = React.useState<IFace[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [lcLinks,setLcLinks] = React.useState<string[]>([]);

    //file upload
    const MAX_SIZE = 5242880;
    const [prevfile, setPrevFile] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [currentFile, setCurrentFile] = useState<any>(undefined);
    const [progress, setProgress] = useState(0);

    const faceService = FaceService.getInstance();

    const handleAdBlock= () => {
      var ads = document.getElementById('ads');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_m');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_sm');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
    }

    useEffect(()=>{
      document.title = 'CamgirlCrawler is a facial recognition camgirl model search engine';
      let descMeta=document.querySelector("meta[name='description']");
      if(descMeta!==null)descMeta.setAttribute("content", 'Camgirl model finder reverse image facial recognition search engine.Upload a face image to search and find camgirls using facial recognition image search.');
      let canNAme=document.querySelector("link[rel='canonical']");
      if(canNAme!==null)canNAme.setAttribute("href", 'https://camgirlcrawler.com/');

      faceService.getPopularSearches().then(response => { 
            if(response.length >0 && IsFace(response[0])){
              popPopFaceSearches(response);
              let tmpLinks: string[] = [];
              response.map((face: IFace) => {
                tmpLinks.push("https://ttedwm.com/embed/lf?c=object_container&site=jasmin&cobrandId=&psid=camgirlcrawler&pstool=202_1&psprogram=revs&campaign_id=&category=girl&forcedPerformers[]=" + face.name + "&vp[showChat]=&vp[chatAutoHide]=&vp[showCallToAction]=&vp[showPerformerName]=true&vp[showPerformerStatus]=true&ms_notrack=1"); 
              });
              setLcLinks(tmpLinks);
            }else{
              popFaceData([]);
            } 
          }).catch(error => {
              console.log(error)
          });
      setProgress(0);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
       return () => { if(prevfile!==undefined) URL.revokeObjectURL(prevfile.preview)};
       //setShow(true);      
  }, []); // empty array means only once

  //file upload
  const onDrop = (files:any) => {

    resetUpload();

    if (files.length > 0 && files[0].size > MAX_SIZE) {
      searchAgain();
      setErrorMsg(`file too big. Your file is ${files[0].size} KB, Max is ${MAX_SIZE} KB`);
      return;
    }

    if (files.length > 0) {
      setSelectedFiles(files);
      let currentFile = files[0];
      setCurrentFile(currentFile);
      setPrevFile(() => Object.assign(currentFile, {
        preview: URL.createObjectURL(currentFile)
      }
    ));
    }
  };

  const upload = () => {

    setLoading(true);

    faceService.imageSearch(currentFile, (event:any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then(response => { 
      if(response.length >0 && IsFace(response[0])){
        popFaceData(response);
        }else{
          setErrorMsg(handleHttpError(response));
      } 
    }).catch(error => {
      setErrorMsg(handleHttpError(500));
      console.log(error)
    }).then(() =>{
          setLoading(false);
          setFileUploaded(true);
          var resultsDiv = document.getElementById('results');
          if(resultsDiv)
            resultsDiv.scrollIntoView({behavior: 'smooth', block: 'center'})
    });
  }

  const resetUpload = () => {
    setProgress(0);
    setErrorMsg("");
    setFileUploaded(false);
    popFaceData([]);
   }   
   
  const searchAgain = () => {
    var dzDiv = document.getElementById('dropzone_container');
    if(dzDiv)
      dzDiv.scrollIntoView({behavior: 'smooth', block: 'start'})
    resetUpload();
    setSelectedFiles([]);
    let currentFile = undefined;
    setCurrentFile(currentFile);
   }  
    
   // this is manly to stop typescript from complaining 
   const AdBlockFn = handleAdBlock as any;

 return (
  <div>
    <Helmet>
    {!adBlockDetected && lcLinks.length>0 &&  lcLinks.map((l, index) => (
                <script defer key={index} src={l}></script>
      ))    
    }
    </Helmet>
    <AdBlockFn/>
      <section>
            <div className='pageHeader'>
              <h1>Camgirl reverse face image search engine</h1>
              <div><b><i>Find your favourite camgirl model again!</i></b></div>
            </div>
            <div className='info' id='info'>
                Welcome to our camgirl reverse face image search engine. 
                To find your favourite camgirl model again or even similar looking camgirls simply upload an image of her face and press 'Start Search'. 
                For best results use a cropped full frontal face image of just her face exluding any distractions or additional faces in the background. 
                We use the latest AI enable face recognition libraries to return the most accurate face matches to the girl you are searching for.
                We also regularly crawl the most popular camgirl sites in order to find the faces of new girls and models so you can find your favourite 
          </div>
      </section>
      {adBlockDetected && (
            <div className="alert alert-danger errorMessage" role="alert" style={{marginTop:'30px'}}>
                If you are using an Ad Blocker links to models may not work!<br/>Please turn off your Ad Blocker!
            </div>
       )}
    <div id='dropzone_container'>
    {!fileUploaded && (
      <div>
        {selectedFiles[0] ? (
            <div onClick={searchAgain} style={{maxWidth: '800px',margin:'auto',textAlign:'right'}}>
              <img src={'images/reload.png'} alt="Reload" title='Reload' width={'25px'} style={{cursor:'pointer'}}/>
          </div> 
          ):(
            <div style={{maxWidth: '800px',height:'25px',margin:'auto'}}></div>
          )}
      <Dropzone onDrop={onDrop} multiple={false} onFileDialogOpen={resetUpload} onDragOver={resetUpload}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {selectedFiles[0] && selectedFiles[0].name ? (
                <div>          
                    {prevfile && (
                      <div className='dropzone-thumbsOutter'>
                        <div className='dropzone-thumbsContainer'>
                          <div className='dropzone-thumb'>
                            <div className='dropzone-thumbInner'>
                              <img
                                  src={prevfile.preview} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}         
                  <div className="selected-file">
                    {selectedFiles && selectedFiles[0].name}
                  </div>
                </div>
              ) : (
                <div className='dropzone-file-types'>
                  <div>Drop face image here to upload (.jpg, .png, .gif, .webp)</div>
                  <br/>
                  or
                  <br/><br/>
                </div>
              )}
              {currentFile ===undefined && (
                <div>
                  <div>
                    <button className="btn btn-success" onClick={onDrop}>
                          Browse for face image
                        </button>
                    </div>
                  <div className='dropzone-file-size'>
                    <div>Max face image file size: 5MB</div>
                  </div>
                </div>
              )}
            </div>
            <div style={{display: 'inline-block'}}>
              <aside className="file-selected-wrapper">
                {currentFile && (
                <button
                  className="btn btn-success"
                  disabled={loading}
                  onClick={upload}
                >
                  Start Search
                </button>
                )}
              </aside>
            </div>
            <div style={{display: 'inline-block'}}>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>          
          </section>
        )}
      </Dropzone>
    </div>
    )}
    </div>
      {loading && (
        <div className="progress mx-auto mt-3" style={{width: "75%"}}>
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
        <div id="results">
        {fileUploaded && (
          <div>
            <div><br/><h3>Search results for image:</h3></div>
          </div>
        )}
        {!fileUploaded && errorMsg && (
              <div className="alert alert-danger message" role="alert">
                {errorMsg}
              </div>
         )}
        {fileUploaded && (
          <div>
                      <div className='resultsThumbsOutter'>
                        <div className='thumbsContainer'>
                          <div className='thumb'>
                            <div className='thumbInner'>
                              <img
                                  src={prevfile.preview}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => { URL.revokeObjectURL(prevfile.preview) }} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
             {!errorMsg && faces.length == 1 &&  (
              <div className="resultsMessage">
                {faces.length} possible match found:
              </div>
              )}
              {!errorMsg && faces.length >1 &&  (
              <div className="resultsMessage">
                {faces.length} possible matches found:
              </div>
              )}
             {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter'>
              {!errorMsg && faces.length>0 &&  faces.map((face: IFace) => (
                <FaceComponent key={face.name}  face={face} imgWidth='150' imgHeight='150'/>
                    ))    
                } 
            </div>
          </div>
        )}
          <div className='info' style={{marginTop:'30px'}}>
                For more info on getting the best facial recognition results see our<br/>
                <a href='camgirl-facial-recognition-search-guide.html'>Camgirl facial recognition search guide</a>
          </div> 
        {fileUploaded && (
            <div style={{margin:'30px'}}>
            <button
                  className="btn btn-success"
                  onClick={searchAgain}>
                   Search again
                </button>
            </div> 
            )} 
          </div>
          <div style={{marginTop: "50px"}} hidden={popFaceSearches.length==0}>
          <section>
          <h2>Popular camgirl face searches:</h2>
          <div className='resultsThumbsOutter' style={{marginTop: '30px',marginBottom: '50px'}}>
              {!adBlockDetected && popFaceSearches.map((face: IFace,index) => (
                  <div key={index} className='thumb' style={{width:'200px',height:'200px'}}>
                    <div id="object_container"></div>
                  </div>
                ))    
              } 
              {adBlockDetected && popFaceSearches.map((face: IFace) => (
                    <FaceComponent key={face.name}  face={face} imgWidth='150' imgHeight='150'/>
                ))         
              } 
          </div>
          </section>
          </div> 
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h3>Camgirl face search results explainer:</h3>
                <div className='explainer'>
                    <picture>
                      <source type="image/webp" srcSet ="images/explainer.webp"/>
                      <source type="image/jpg" srcSet ="images/explainer.jpg"/>
                      <img src="images/best_face.jpg" alt="Camgirl search results explainer" style={{marginTop:'30px'}} width={'330px'} height={'354px'}/>
                    </picture>
                    <div>
                      <ol>
                        <li>Web site the camgirl performs on.</li>
                        <li>% likely it's a match. 100% is a perfect match.</li>
                        <li>Gender, this is not 100% reliable.*</li>
                        <li>The last time our crawler saw the camgirl online</li>
                      </ol>
                    </div>
                </div>     
            </section>
          </div>
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h4>What we do:</h4>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li >Crawl popular camgirl model plaforms for face images</li>
                        <li>Retain a camgirl model's face images for up to six months for searches</li>             
                        <li>Perform face image search engine matching for camgirl model's</li>
                        <li>Provide a tag and keyword search engine for finding camgirl model's</li>
                        <li>Link to a camgirl model found in a face image or tag search</li>
                        <li>Work with camgirl model plaforms as an affiliate</li>
                        <li>Respect camgirl model's privacy</li> 
                        <li>Respect your (the user's) privacy</li>
                    </div>    
            </section>
          </div>
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h5>What we don't do:</h5>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li>Post the real name of camgirl models</li> 
                        <li>Retain camgirl model's face images beyond six months if not seen in searches</li>
                        <li>Show recorded videos of a camgirl model performing</li>
                        <li>Find a camgirl model's real social media accounts</li>
                        <li>Charge for our camgirl model face image search service</li> 
                        <li>Retain any of your (the user's) usage details</li>              
                    </div>    
            </section>
          </div>
    </div>
  );
};

  export default Faces;