import * as React from 'react';
import { useEffect,useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import FaceService from '../services/face.service';
import FaceComponent from '../components/Face.Component';
import {IFace } from '../@types/Face';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';
import { useDetectAdBlock } from "adblock-detect-react";

const SearchComponent:React.FC = () => {
    const adBlockDetected = useDetectAdBlock();

    let defaultFace: IFace = { name:'',site:'' ,gender:'' ,siteCode:'' ,thumbnailUrl:'' ,personUrl:'' ,certainty:'',
                                lastSeen:new Date(), lastSeenMsg:'',isOnline:false}; 

    const location = useLocation();
    const site = location.pathname.split("/")[2];
    const name = location.pathname.split("/")[3];
    const [face,popFaceData] = React.useState<IFace>(defaultFace);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const faceService = FaceService.getInstance();

    const handleAdBlock= () => {
      var ads = document.getElementById('ads');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_m');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
        ads = document.getElementById('ads_sm');
      if(adBlockDetected && ads!=null) ads.style.display = 'none';
    }

    useEffect(()=>{ 
      document.title = 'CamgirlCrawler model search by model name';
      let descMeta=document.querySelector("meta[name='description']");
      if(descMeta!==null)descMeta.setAttribute("content", 'Camgirl model finder by model name. Search and find camgirls using model name.');
      let canNAme=document.querySelector("link[rel='canonical']");
      if(canNAme!==null)canNAme.setAttribute("href", 'https://camgirlcrawler.com/model/lj/');

        setLoading(true);

        faceService.getDefaultThumbnail(site,name).then(response => { 
          if(IsFace(response)){
            response.thumbnailUrl = response.thumbnailUrl;
            popFaceData(response);
            }else{
              setErrorMsg(handleHttpError(response));
          } 
        }).catch(error => {
          setErrorMsg(handleHttpError(500));
          console.log(error)
        }).then(() =>{
              setLoading(false);
        });
       }, []); // empty array means only once

       function openInNewTab(url:string){
        window.open(url, "_blank", "noreferrer");
      };

        // this is manly to stop typescript from complaining 
     const AdBlockFn = handleAdBlock as any;

    return (
        <div>
            <AdBlockFn/>
            {adBlockDetected && (
              <div className="alert alert-danger errorMessage" role="alert" style={{marginTop:'30px'}}>
                If you are using an Ad Blocker the link to model may not work!<br/>Please turn off your Ad Blocker!
              </div>
            )}
            <h1 style={{marginTop:'50px'}}>{face.name}</h1>
            <div>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>  
            {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter' style={{marginTop:'10px'}}>
              {!errorMsg && !loading && (
                <FaceComponent key={face.thumbnailUrl}  face={face} imgWidth={'450'} imgHeight={'450'} />
                )} 
            </div>
            <div style={{marginTop:'30px'}}> 
            <button className="btn btn-success"  onClick={() => openInNewTab(face.personUrl)} style={{cursor:'pointer'}}>
                          Visit {face.name}
                        </button>
            </div>
            <div className='info' style={{marginTop:'75px'}}>
            <section>
              <h2>Cam girl face search results explainer:</h2>
                <div className='explainer'>
                    <picture>
                      <source type="image/webp" srcSet ="images/explainer.webp"/>
                      <source type="image/jpg" srcSet ="images/explainer.jpg"/>
                      <img src="images/best_face.jpg" alt="Cam girl search results explainer" style={{marginTop:'30px'}} width={'330px'} height={'354px'}/>
                    </picture>
                    <div>
                      <ol>
                        <li>Web site the cam girl performs on.</li>
                        <li>% likely it's a match. 100% is a perfect match.</li>
                        <li>Gender, this is not 100% reliable.*</li>
                        <li>The last time our crawler saw the cam girl online</li>
                      </ol>
                    </div>
                </div>     
            </section>
            <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h3>What we do:</h3>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li >Crawl popular camgirl model plaforms for face images</li>
                        <li>Retain a camgirl model's face images for up to six months for searches</li>             
                        <li>Perform face image search engine matching for camgirl model's</li>
                        <li>Provide a tag and keyword search engine for finding camgirl model's</li>
                        <li>Link to a camgirl model found in a face image or tag search</li>
                        <li>Work with camgirl model plaforms as an affiliate</li>
                        <li>Respect camgirl model's privacy</li> 
                        <li>Respect your (the user's) privacy</li>
                    </div>    
            </section>
          </div>
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h4>What we don't do:</h4>
              <br/>
                    <div style={{textAlign:'center',width:'100%',margin:'auto'}}>
                        <li>Post the real name of camgirl models</li> 
                        <li>Retain camgirl model's face images beyond six months if not seen in searches</li>
                        <li>Show recorded videos of a camgirl model performing</li>
                        <li>Find a camgirl model's real social media accounts</li>
                        <li>Charge for our camgirl model face image search service</li> 
                        <li>Retain any of your (the user's) usage details</li>              
                    </div>    
            </section>
          </div>
          </div>
        </div>
  );
}
export default SearchComponent;